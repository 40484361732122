<script setup>
import helper from '~/resources/js/libraries/helper';
import FavoriteCard from './favorite-card.vue';

defineProps({
    listings: {
        type: Array,
        required: true,
    },
});
const currency = ref(helper.cookies.currency());
</script>

<template>
    <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-2 mx-0 mt-2">
        <div v-for="listing in listings" :key="listing._id" itemscope itemtype="https://schema.org/RealEstateListing">
            <div class="relative">
                <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: listing.slug } })" class="bg-indigo-50 p-2 grid lg:grid-cols-2 md:grid-cols-2 gap-2 rounded h-60 relative" itemprop="url">

                    <img :src="`${listing.picture}?w=630&h=550&fit=contain`" :alt="listing.title"
                        class="rounded h-56 w-80 object-cover" itemprop="thumbnailUrl">
                    <div class="flex flex-col gap-2">
                        <span class="text-xs text-gray-500">#{{ listing._id }}</span>
                        <h6 class="text-md text-indigo-800 line-clamp-2 font-medium" itemprop="name">{{ listing.title }}</h6>
                        <h5 class="text-md text-gray-950">
                            {{ helper.formatPrice(listing.sale_price) }}
                            {{ currency.symbol }}
                        </h5>
                        <div class="grid grid-cols-2 text-xs -my-1" v-for="feature in listing.features">
                            <span class="">{{ feature.name }} :</span>
                            <span class="text-gray-600">{{ feature.value.label_value || feature.value }}</span>
                        </div>
                        <div class="grid grid-cols-2 text-xs gap-1">
                            <span>Ad Date :</span>
                            <span class="text-gray-600">{{ (listing.updated_at ? new Date(listing.updated_at) : new Date(listing.created_at)).toLocaleDateString() }}</span>
                            <span>City / Town :</span>
                            <span class="text-gray-600 truncate">{{ listing.city_town }}</span>
                        </div>
                    </div>
                </NuxtLink>
                <div class="absolute top-4 left-4" @click.stop="">
                        <FavoriteCard :listingId="listing._id"></FavoriteCard>
                </div>
            </div>
        </div>
    </div>
</template>